@import '@taiga-ui/core/styles/taiga-ui-theme.less';
@import '@taiga-ui/core/styles/taiga-ui-fonts.less';
@import '@taiga-ui/styles/taiga-ui-global.less';

@import './app/shared/styles/variables.less';
@import './app/shared/styles/initialize.less';
@import './app/shared/styles/fonts.less';
@import './app/shared/styles/typography.less';
@import './app/shared/styles/pos-layout.less';
@import './app/shared/styles/flex.less';
@import './app/shared/styles/generator.less';
@import './app/shared/styles/buttons.less';
@import './app/shared/styles/inputs.less';
@import './app/shared/styles/dialogs.less';
@import './app/shared/styles/taiga-overrides.less';
@import './app/shared/styles/material-overrides.less';
@import './app/shared/styles/article.less';

html,
body {
	height: 100%;
}

body {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
}

app-root {
	display: block;
	height: 100%
}

tui-root {
	display: block;
	height: 100%
}
