// Headings

.app-text__h1 {
	font: var(--app-font-heading-xl);
}

.app-text__h2 {
	font: var(--app-font-heading-l);
}

.app-text__h3 {
	font: var(--app-font-heading-m);
}

.app-text__h4 {
	font: var(--app-font-heading-s);
}

.app-text__h4-2 {
	font: var(--app-font-heading-s-2);
}

// Body

.app-text__body-xl {
	font: var(--app-font-text-xl);
}

.app-text__body-l {
	font: var(--app-font-text-l);
}

.app-text__body-m {
	font: var(--app-font-text-m);
}

.app-text__body-s {
	font: var(--app-font-text-s);
}

.app-text__body-xs {
	font: var(--app-font-text-xs);
}

// Labels

.app-text__label-xs {
	font: var(--app-font-text-xs);
	margin-bottom: 12px;
}

// Links
a {
	text-decoration: none;
	outline: none;
}