@import 'media-definitions.less';

.generate-columns(12, 64, ~'', false);
.generate-columns(12, 0, ~'', true);
.generate-columns(64, 4, ~'i', false);

@media @desktop {
	.generate-columns(12, 64, -d, false);
	.generate-columns(12, 0, -d, true);
	.generate-columns(64, 4, i-d, false);
}

@media @tablet {
	.generate-columns(12, 64, -t, false);
	.generate-columns(12, 0, -t, true);
	.generate-columns(64, 4, i-t, false);
}

@media @mobile {
	.generate-columns(12, 64, -m, false);
	.generate-columns(12, 0, -m, true);
	.generate-columns(64, 4, i-m, false);
}

//////////////////////////////////////////////////
// Размножитель академика Саламбекова
//////////////////////////////////////////////////

.cw(@value) {
    width: @value;
}

.ch(@value) {
    height: @value;
}

.pl(@value) {
    padding-left: @value;
}

.pr(@value) {
    padding-right: @value;
}

.pt(@value) {
    padding-top: @value;
}

.pb(@value) {
    padding-bottom: @value;
}

.ml(@value) {
    margin-left: @value;
}

.mr(@value) {
    margin-right: @value;
}

.mt(@value) {
    margin-top: @value;
}

.mb(@value) {
    margin-bottom: @value;
}

.m(@value) {
	margin: @value;
}

.p(@value) {
	padding: @value;
}

.gap(@value) {
	gap: @value;
}

.generate-columns(@n, @grid-size, @responsive-prefix, @is-relative, @i: 0) when (@i =< @n) {
	.absolute();

	.absolute() when (not (@grid-size = initial) and not (@is-relative)) {
		.cw@{responsive-prefix}-@{i} {
			.cw(unit(@i * @grid-size, px));
		}

		.ch@{responsive-prefix}-@{i} {
			.ch(unit(@i * @grid-size, px));
		}

		.pl@{responsive-prefix}-@{i} {
			.pl(unit(@i * @grid-size, px));
		}

		.pr@{responsive-prefix}-@{i} {
			.pr(unit(@i * @grid-size, px));
		}

		.pt@{responsive-prefix}-@{i} {
			.pt(unit(@i * @grid-size, px));
		}

		.pb@{responsive-prefix}-@{i} {
			.pb(unit(@i * @grid-size, px));
		}

		.ml@{responsive-prefix}-@{i} {
			.ml(unit(@i * @grid-size, px));
		}

		.mr@{responsive-prefix}-@{i} {
			.mr(unit(@i * @grid-size, px));
		}

		.mt@{responsive-prefix}-@{i} {
			.mt(unit(@i * @grid-size, px));
		}

		.mb@{responsive-prefix}-@{i} {
			.mb(unit(@i * @grid-size, px));
		}

		.m@{responsive-prefix}-@{i} {
			.m(unit(@i * @grid-size, px));
		}

		.p@{responsive-prefix}-@{i} {
			.p(unit(@i * @grid-size, px));
		}

		.gap@{responsive-prefix}-@{i} {
			.gap(unit(@i * @grid-size, px));
		}
	}

	.relative();

	.relative() when ((@is-relative) and not (@grid-size = initial)) {
		.cw@{responsive-prefix}-r-@{i} {
			.cw(percentage((@i / @n)));
		}

		.cvw@{responsive-prefix}-r-@{i} {
			.cw(unit(percentage((@i / @n)), vw));
		}

		.ch@{responsive-prefix}-r-@{i} {
			.ch(percentage((@i / @n)));
		}

		.cvh@{responsive-prefix}-r-@{i} {
			.ch(unit(percentage((@i / @n)), vh));
		}
	}

	.generate-columns(@n, @grid-size, @responsive-prefix, @is-relative, (@i + 1));
}
