.mdc-tooltip__surface {
	font: var(--app-font-text-s) !important;
	background: #303030 !important;
	border: 1px solid rgba(255, 255, 255, 0.1) !important;
	border-radius: 8px !important;
	padding: 8px !important;
}

.cdk-overlay-dark-backdrop {
	background: rgba(0, 0, 0, .62);
}