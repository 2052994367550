:root {
	// Typography
	--app-font-face-heading: 'Rubik Medium';
	--app-font-face-text: 'Rubik Regular';
	--app-font-face-light: 'Rubik Light';

	--app-font-heading-xl: 28px/33px var(--app-font-face-heading);
	--app-font-heading-l: 20px/16px var(--app-font-face-heading);
	--app-font-heading-ml: 17px/20px var(--app-font-face-heading);
	--app-font-heading-m: 12px/16px var(--app-font-face-heading);
	--app-font-heading-sm: 11px/13px var(--app-font-face-heading);
	--app-font-heading-s: 10px/16px var(--app-font-face-heading);
	--app-font-heading-s-2: 10px/17px var(--app-font-face-heading);

	--app-font-text-xl: 16px/19px var(--app-font-face-text);
	--app-font-text-l: 14px/17px var(--app-font-face-text);
	--app-font-text-m: 12px/16px var(--app-font-face-text);
	--app-font-text-s: 11px/13px var(--app-font-face-text);
	--app-font-text-xs: 9px/11px var(--app-font-face-text);

	--app-font-caption-xl: 72px/85px var(--app-font-face-light);
	--app-font-caption-l: 28px/33px var(--app-font-face-light);
	--app-font-caption-m: 22px/26px var(--app-font-face-light);

	// Colors
	--app-background: #fff;
	--app-text: #303030;
	--app-text-inactive: rgba(48, 48, 48, 0.45);
	--app-text-correct: #00BB99;
	--app-sign-correct: rgba(0, 187, 153, 0.76);
	--app-box-correct: rgba(0, 187, 153, 0.5);	
	--app-text-error: #d63031;
	--app-validation-error: #d63031;
	--app-sign-error: rgba(255, 30, 84, 0.76);
	--app-box-error: rgba(255, 30, 84, 0.5);
	--app-active: #FF4D01;
	--app-inactive: rgba(255, 255, 255, 0.24);
	--app-selected: #01C2FF;
	--app-info: #0984e3;
	--app-cta: #6c5ce7;
	--app-cta-accent: #6556d4;
	--app-primary: #353535;
	--app-bubble: rgba(255, 255, 255, 0.15);
	--app-outline: #fff;
	--app-info-plate-light: rgba(0, 0, 0, 0.4);
	--app-info-plate: rgba(0, 0, 0, 0.6);
	--app-info-plate-dark: rgba(0, 0, 0, 0.8);

	// Radiuses
	--app-radius-m: 20px;
	--app-radius-s: 8px;
	
	// Sizes
	--app-height-xl: 64px;
	--app-height-l: 48px;
	--app-height-m: 32px;
	--app-height-s: 24px;

	// Shadows
	--app-shadow-xs: 6px 6px 16px #d5d5d5, -6px -6px 16px #ffffff;
	--app-shadow-s: 7px 7px 14px #b7b7b7, -7px -7px 14px #ffffff;
	--app-shadow-m: 6px 6px 12px #c2c2c2, -6px -6px 12px #ffffff;
	--app-shadow-l: 20px 20px 60px #c7c7c7, -20px -20px 60px #ffffff;
}