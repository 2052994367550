tui-alert {
	border-radius: var(--app-radius-s) !important;
	box-shadow: none !important;
	background: none !important;
}

tui-notification {
	color: var(--app-sign-error) !important;
	background: rgb(27, 7, 39) !important;
	border-radius: var(--app-radius-s) !important;
	//background: linear-gradient(112.81deg, rgba(0, 0, 0, 0.5), rgb(27, 7, 39)), #000;
	font: var(--app-font-text-m) !important;

	tui-svg {
		color: var(--app-box-error) !important;
	}

	.t-heading {
		font: var(--app-font-text-l) !important;
		color: var(--app-text) !important;
		display: block;
		margin-top: 2px !important;
	}

	.t-content {
		color: var(--app-text-inactive) !important;

		tui-svg {
			color: var(--app-text) !important;
		}
	}
}