// RUBIK

@font-face {
	font-family: 'Rubik Regular';
	src: url(../../../assets/fonts/rubik/Rubik-Regular.ttf);
}

@font-face {
	font-family: 'Rubik Medium';
	src: url(../../../assets/fonts/rubik/Rubik-Medium.ttf);
}

@font-face {
	font-family: 'Rubik Light';
	src: url(../../../assets/fonts/rubik/Rubik-Light.ttf);
}

// LATO

@font-face {
	font-family: 'Lato Regular';
	src: url(../../../assets/fonts/lato/Lato-Regular.ttf);
}

@font-face {
	font-family: 'Lato Medium';
	src: url(../../../assets/fonts/lato/Lato-Medium.ttf);
}

@font-face {
	font-family: 'Lato Light';
	src: url(../../../assets/fonts/lato/Lato-Thin.ttf);
}