@import 'media-definitions.less';

.update-dialog {
	padding: 36px;
	background-color: var(--app-background);
	backdrop-filter: blur(4px);
	border-radius: var(--app-radius-s);

	.cdk-dialog-container {
		outline: none !important;
	}
}

.article-dialog {
	padding: 0;
	border-radius: 8px;

	.cdk-dialog-container {
		outline: none !important;
	}
}

.article-dialog-backdrop {
	//top: 71px;
	background: rgba(0, 0, 0, 0.49);
	backdrop-filter: blur(10px);
}

@media @mobile {
	.update-dialog {
		width: 100%;
	}
}