@import 'media-definitions.less';

.button-line {
	display: flex;	
	gap: 20px;
}

.pseudo-button {
	display: flex;
	align-items: center;
	gap: 8px;
	font: var(--app-font-text-s);
	cursor: pointer;

	&.disabled {
		cursor: default;
		opacity: 0.45;
	}
}

@media @mobile {
	.button-line {
		flex-direction: column;

		app-button {
			width: 100% !important;
		}
	}
}