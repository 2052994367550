textarea {
	resize: none;
	height: 120px !important;

	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 8px;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-track {
		background-color: #f0f0f0;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #e0e0e0;
		border-radius: 4px;
	}
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}