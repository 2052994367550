:root {
	--ck-color-image-caption-background: hsl(0, 0%, 97%);
	--ck-color-image-caption-text: hsl(0, 0%, 20%);
	--ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
	--ck-color-mention-text: hsl(341, 100%, 30%);
	--ck-color-table-caption-background: hsl(0, 0%, 97%);
	--ck-color-table-caption-text: hsl(0, 0%, 20%);
	--ck-highlight-marker-blue: hsl(201, 97%, 72%);
	--ck-highlight-marker-green: hsl(120, 93%, 68%);
	--ck-highlight-marker-pink: hsl(345, 96%, 73%);
	--ck-highlight-marker-yellow: hsl(60, 97%, 73%);
	--ck-highlight-pen-green: hsl(112, 100%, 27%);
	--ck-highlight-pen-red: hsl(0, 85%, 49%);
	--ck-image-style-spacing: 1.5em;
	--ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
	--ck-todo-list-checkmark-size: 16px;
}

.article-outer-wrapper {

	--background-image: none;

	background-color: #fff;

	background-image: var(--background-image) !important;
	background-size: cover !important;
	background-position-x: center !important;
	background-position-y: center !important;

	.article-wrapper {
		position: relative;
		z-index: 0;
		min-height: 100%;

		font: var(--app-font-text-m) !important;

		p {
			margin: 0;
			font: var(--app-font-text-m) !important;
		}

		.article-img-bottom-right {
			position: absolute !important;
			bottom: 0;
			right: 0;
		}

		.article-text {
			font: var(--app-font-text-m) !important;
		}

		.article-h1 {
			font: var(--app-font-heading-xl) !important;
		}

		.article-h2 {
			font: var(--app-font-heading-l) !important;
		}

		.article-h3 {
			font: var(--app-font-heading-m) !important;
		}

		.article-h4 {
			font: var(--app-font-heading-s) !important;
		}

		////////////////////////
		// Стили в соответствии с "идеями" ck-editor
		
		////////////////////////
		// IMAGES

		.image {
			display: table;
			clear: both;
			text-align: center;
			margin: 0.9em auto;
			min-width: 50px;
		}

		.image img {
			display: block;
			margin: 0 auto;
			max-width: 100%;
			min-width: 100%;
		}

		.image-inline {
			display: inline-flex;
			max-width: 100%;
			align-items: flex-start;
		}

		.image-inline picture {
			display: flex;
		}

		.image-inline picture,
		.image-inline img {
			flex-grow: 1;
			flex-shrink: 1;
			max-width: 100%;
		}

		.image.image_resized {
			max-width: 100%;
			display: block;
			box-sizing: border-box;
		}

		.image.image_resized img {
			width: 100%;
		}

		.image.image_resized>figcaption {
			display: block;
		}

		.image-style-block-align-left,
		.image-style-block-align-right {
			max-width: calc(100% - var(--ck-image-style-spacing));
		}

		.image-style-align-left,
		.image-style-align-right {
			clear: none;
		}

		.image-style-side {
			float: right;
			margin-left: var(--ck-image-style-spacing);
			max-width: 50%;
		}

		.image-style-align-left {
			float: left;
			margin-right: var(--ck-image-style-spacing);
		}

		.image-style-align-center {
			margin-left: auto;
			margin-right: auto;
		}

		.image-style-align-right {
			float: right;
			margin-left: var(--ck-image-style-spacing);
		}

		.image-style-block-align-right {
			margin-right: 0;
			margin-left: auto;
		}

		.image-style-block-align-left {
			margin-left: 0;
			margin-right: auto;
		}

		p+.image-style-align-left,
		p+.image-style-align-right,
		p+.image-style-side {
			margin-top: 0;
		}

		.image-inline.image-style-align-left,
		.image-inline.image-style-align-right {
			margin-top: var(--ck-inline-image-style-spacing);
			margin-bottom: var(--ck-inline-image-style-spacing);
		}

		.image-inline.image-style-align-left {
			margin-right: var(--ck-inline-image-style-spacing);
		}

		.image-inline.image-style-align-right {
			margin-left: var(--ck-inline-image-style-spacing);
		}

		.image>figcaption {
			display: table-caption;
			caption-side: bottom;
			word-break: break-word;
			color: var(--ck-color-image-caption-text);
			background-color: var(--ck-color-image-caption-background);
			padding: .6em;
			font-size: .75em;
			outline-offset: -1px;
		}

		///////////////////////////////
		// LISTS

		ul, ol,
		ol li,
		ul li {
			list-style-type: inherit;
		}

		ul, ol {
			color: #303030;
			padding-left: 40px;
		}

		ol {
			list-style-type: decimal;
		}

		
		ol ol {
			list-style-type: lower-latin;
		}

		ol ol ol {
			list-style-type: lower-roman;
		}

		ol ol ol ol {
			list-style-type: upper-latin;
		}

		
		ol ol ol ol ol {
			list-style-type: upper-roman;
		}

		ul {
			list-style-type: disc;
		}

		ul ul {
			list-style-type: circle;
		}

		ul ul ul {
			list-style-type: square;
		}

		ul ul ul ul {
			list-style-type: square;
		}

		///////////////////////////////
		// TABLES

		.table {
			margin: 0.9em auto;
			display: table;
		}

		.table table {
			border-collapse: collapse;
			border-spacing: 0;
			width: 100%;
			height: 100%;
			border: 1px double hsl(0, 0%, 70%);
		}

		.table table td,
		.table table th {
			min-width: 2em;
			padding: .4em;
			border: 1px solid hsl(0, 0%, 75%);
		}

		.table table th {
			font-weight: bold;
			background: hsla(0, 0%, 0%, 5%);
		}

		.table .ck-table-resized {
			table-layout: fixed;
		}

		.table table {
			overflow: hidden;
		}

		.table td,
		.table th {
			position: relative;
		}

		///////////////////////////////

		* {
			color: inherit;
		}		
	}
}