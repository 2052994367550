@import 'media-definitions.less';

.flex,
.inline-flex {
	display: flex;

	&.equal-proportion {
		>* {
			flex-grow: 1;
			flex-basis: 0;
			min-width: 0;
		}
	}
}

.flex-fill {
	flex-grow: 1;
	flex-shrink: 1;
}

.flex-grow {
	flex-grow: 1;
}

.flex-shrink {
	flex-shrink: 1;
}

.inline-flex {
	display: inline-flex;
}

.flex-dir-row {
	flex-direction: row;
}

.flex-dir-row-reverse {
	flex-direction: row-reverse;
}

.flex-dir-column {
	flex-direction: column;
}

.flex-dir-column-reverse {
	flex-direction: column-reverse;
}

.flex-ai-center {
	align-items: center;
}

.flex-ai-start {
	align-items: flex-start;
}

.flex-ai-end {
	align-items: flex-end;
}

.flex-ai-stretch {
	align-items: stretch;
}

.flex-ai-baseline {
	align-items: baseline;
}

.flex-as-center {
	align-self: center;
}

.flex-as-start {
	align-self: flex-start;
}

.flex-as-end {
	align-self: flex-end;
}

.flex-as-stretch {
	align-self: stretch;
}

.flex-jc-center {
	justify-content: center;
}

.flex-jc-space-between {
	justify-content: space-between;
}

.flex-jc-space-around {
	justify-content: space-around;
}

.flex-jc-space-evenly {
	justify-content: space-evenly;
}

.flex-jc-start {
	justify-content: flex-start;
}

.flex-jc-end {
	justify-content: flex-end;
}

.flex-ac-center {
	align-content: center;
}

.flex-ac-space-between {
	align-content: space-between;
}

.flex-ac-space-around {
	align-content: space-around;
}

.flex-ac-space-evenly {
	align-content: space-evenly;
}

.flex-ac-start {
	align-content: flex-start;
}

.flex-ac-end {
	align-content: flex-end;
}

.flex-ac-stretch {
	align-content: stretch;
}

.flex-no-wrap {
	flex-wrap: nowrap;
}

.flex-wrap {
	flex-wrap: wrap;
}

@media @mobile {
	.m-inline-flex {
		display: inline-flex;
	}

	.flex-m-dir-row {
		flex-direction: row;
	}

	.flex-m-dir-row-reverse {
		flex-direction: row-reverse;
	}

	.flex-m-dir-column {
		flex-direction: column;
	}

	.flex-m-dir-column-reverse {
		flex-direction: column-reverse;
	}

	.flex-m-ai-center {
		align-items: center;
	}

	.flex-m-ai-start {
		align-items: flex-start;
	}

	.flex-m-ai-end {
		align-items: flex-end;
	}

	.flex-m-ai-stretch {
		align-items: stretch;
	}

	.flex-m-ai-baseline {
		align-items: baseline;
	}

	.flex-m-as-center {
		align-self: center;
	}

	.flex-m-as-start {
		align-self: flex-start;
	}

	.flex-m-as-end {
		align-self: flex-end;
	}

	.flex-m-as-stretch {
		align-self: stretch;
	}

	.flex-m-jc-center {
		justify-content: center;
	}

	.flex-m-jc-space-between {
		justify-content: space-between;
	}

	.flex-m-jc-space-around {
		justify-content: space-around;
	}

	.flex-m-jc-space-evenly {
		justify-content: space-evenly;
	}

	.flex-m-jc-start {
		justify-content: flex-start;
	}

	.flex-m-jc-end {
		justify-content: flex-end;
	}

	.flex-m-ac-center {
		align-content: center;
	}

	.flex-m-ac-space-between {
		align-content: space-between;
	}

	.flex-m-ac-space-around {
		align-content: space-around;
	}

	.flex-m-ac-space-evenly {
		align-content: space-evenly;
	}

	.flex-m-ac-start {
		align-content: flex-start;
	}

	.flex-m-ac-end {
		align-content: flex-end;
	}

	.flex-m-ac-stretch {
		align-content: stretch;
	}

	.flex-m-no-wrap {
		flex-wrap: nowrap;
	}

	.flex-m-wrap {
		flex-wrap: wrap;
	}
}