@import 'media-definitions.less';

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

*, *:after, *:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html, body {
	height: 100%;
}

@media @desktop {
	.resp-mobile {
		display: none !important;
	}
	.resp-tablet {
		display: none !important;
	}
	.hidden-d {
		display: none !important;
	}
}

@media @tablet {
	.resp-desktop {
		display: none !important;
	}
	.resp-mobile {
		display: none !important;
	}
	.hidden-t {
		display: none !important;
	}
}

@media @mobile {
	.resp-desktop {
		display: none !important;
	}
	.resp-tablet {
		display: none !important;
	}
	.hidden-m {
		display: none !important;
	}
}
