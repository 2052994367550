@import 'media-definitions.less';

.wide {
	width: 100%;
}

.tall {
	height: 100%;
}

.table {
	display: table;
}

.cell-wrapper {
	display: table;
	height: 100%;
}

.row {
	display: table-row;
}

.cell {
	display: table-cell;
	vertical-align: middle;
}

.va-top {
	vertical-align: top;
}

.va-bottom {
	vertical-align: bottom;
}

.va-middle {
	vertical-align: middle;
}

.block {
	display: block;
}

.inline-block {
	display: inline-block;
}

.inline {
	display: inline;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.fixed {
	position: fixed;
}

.stick-bottom {
	bottom: 0;
}

.stick-right {
	right: 0;
}

.hidden {
	display: none !important;
}

.hidden-2 {
	margin: 0 !important;
	padding: 0 !important;
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.clip {
	overflow: hidden;
}

.border-box {
	box-sizing: border-box;
}

.content-box {
	box-sizing: content-box;
}

.ta-left {
	text-align: left;
}

.ta-justify {
	text-align: justify;
}

.ta-center {
	text-align: center;
}

.ta-right {
	text-align: right;
}

.block-center {
	margin: 0 auto;
}

.flex-center {
	display: flex;
	justify-content: center;
}

.zero-slim {
	font-size: 0;
}

.no-wrap {
	white-space: nowrap;
}

.inscribed {
	max-width: 100%;
	max-height: 100%;
}

.prevent-select {
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

@media @mobile {
	.m-wide {
		width: 100%;
	}

	.m-tall {
		height: 100%;
	}

	.m-table {
		display: table;
	}

	.m-cell-wrapper {
		display: table;
		height: 100%;
	}

	.m-row {
		display: table-row;
	}

	.m-cell {
		display: table-cell;
		vertical-align: middle;
	}

	.m-va-top {
		vertical-align: top;
	}

	.m-va-bottom {
		vertical-align: bottom;
	}

	.m-va-middle {
		vertical-align: middle;
	}

	.m-block {
		display: block;
	}

	.m-inline-block {
		display: inline-block;
	}

	.m-inline {
		display: inline;
	}

	.m-relative {
		position: relative;
	}

	.m-absolute {
		position: absolute;
	}

	.m-fixed {
		position: fixed;
	}

	.m-stick-bottom {
		bottom: 0;
	}

	.m-stick-right {
		right: 0;
	}

	.m-hidden {
		display: none !important;
	}

	.m-hidden-2 {
		margin: 0 !important;
		padding: 0 !important;
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}

	.m-clip {
		overflow: hidden;
	}

	.m-border-box {
		box-sizing: border-box;
	}

	.m-content-box {
		box-sizing: content-box;
	}

	.m-ta-left {
		text-align: left;
	}

	.m-ta-justify {
		text-align: justify;
	}

	.m-ta-center {
		text-align: center;
	}

	.m-ta-right {
		text-align: right;
	}


	.m-block-center {
		margin: 0 auto;
	}

	.m-zero-slim {
		font-size: 0;
	}

	.m-no-wrap {
		white-space: nowrap;
	}

	.m-inscribed {
		max-width: 100%;
		max-height: 100%;
	}
}